.workflow-builder-wrapper {
  max-width: calc(100% - 100px) !important;
}

#workflowManager {
  /* height: 820px; */
}

#workflowManager .loading-workflow {
  height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  border: 1px solid #dfdfe2;
  border-top: none;
  margin: 0;
}

#workflowManager .loading-workflow-visuals {
  height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  background-color: rgb(243, 243, 243); /* Grey background color */
  border: 1px solid #dfdfe2;
  border-top: none;
  margin: 0;
}

/* drag and drop --- react-flow*/

#workflowManager .canvas-wrapper {
  width: calc(100% - 237px);
  height: 100%;
  color: antiquewhite;
  border-left: 1px solid #dfdfe2;
  border-bottom: 1px solid #dfdfe2;
  background-color: rgb(243, 243, 243); /* Grey background color */
  background-image: radial-gradient(
    rgb(231, 231, 231) 10%,
    transparent 10%
  ); /* Circle pattern */
  background-size: 20px 20px; /* Size of each circle */
}

.canvas-wrapper--full-width {
  width: 100% !important;
}

.react-flow__edge-path {
  stroke-width: 7 !important;
  stroke: #a1a5c6;
}

.react-flow__edge-path:hover {
  cursor: pointer;
}

.react-flow__handle {
  width: 13px;
  height: 13px;
}

.react-flow__handle-right {
  right: -5px;
}

.react-flow__handle-left {
  left: -5px;
}

.animated-running {
  animation: animateBorder 2s linear infinite; /* Define the animation */
}

@keyframes animateBorder {
  0% {
    border-color: transparent; /* Initial border color */
  }
  50% {
    border-color: #54cb75; /* Midpoint border color */
  }
  100% {
    border-color: transparent; /* Final border color (same as initial to create the dashed effect) */
  }
}

#workflowManager div.react-flow__node.selected > div,
#workflowManager div.react-flow__node.selected:hover > div {
  -webkit-box-shadow: 0px 0px 21px 1px rgba(94, 94, 94, 0.6);
  -moz-box-shadow: 0px 0px 21px 1px rgba(94, 94, 94, 0.6);
  box-shadow: 0px 0px 21px 1px rgba(94, 94, 94, 0.6);
}

#workflowManager div.react-flow__node:hover > div {
  -webkit-box-shadow: 0px 0px 21px 1px rgba(94, 94, 94, 0.2);
  -moz-box-shadow: 0px 0px 21px 1px rgba(94, 94, 94, 0.2);
  box-shadow: 0px 0px 21px 1px rgba(94, 94, 94, 0.2);
  /* display: none; */
}

#workflowManager .dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

#workflowManager .dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

#workflowManager .dndflow aside .description {
  margin-bottom: 10px;
}

#workflowManager .dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

#workflowManager .dndflow .dndnode.input {
  border-color: #0041d0;
}

#workflowManager .dndflow .dndnode.output {
  border-color: #ff0072;
}

#workflowManager .dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

#workflowManager .dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  #workflowManager .dndflow {
    flex-direction: row;
  }

  #workflowManager .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

/* react resizable styles*/

#workflowManager .react-resizable {
  position: relative;
}
#workflowManager .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

#workflowManager .react-resizable-handle-w,
#workflowManager .react-resizable-handle-e {
  cursor: ew-resize;
}
#workflowManager .react-resizable-handle-w {
  left: 0;
  height: 100%;
  opacity: 0;
}

#workflowManager .layoutRoot {
  background: #eee;
  flex-wrap: wrap;
}
#workflowManager .absoluteLayout {
  height: calc(100vh - 140px);
  position: relative;
}
#workflowManager .scaledLayout {
  width: 125%;
  left: -12.5vw;
  transform: scale(0.75);
  margin-top: -7.5vh;
}

#workflowManager .box {
  display: flex;
  flex-direction: column;
  background: white;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  min-width: 237px;
  height: 100%;
  overflow: auto;
  z-index: 9;
  border: 1px solid #dfdfe2;
  border-top: none;
}
#workflowManager .box .text {
  text-align: center;
}

#workflowManager .hover-handles .react-resizable-handle {
  display: none;
}
#workflowManager .hover-handles:hover .react-resizable-handle {
  display: block;
}
#workflowManager .absolutely-positioned {
  position: absolute !important;
}

#workflowManager .right-aligned {
  right: 0;
}

#workflowManager .custom-box {
  overflow: visible;
}

/*workflow header styles*/

#workflowManager .workflow-header {
  display: flex;
  height: 82px;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #dfdfe2;
  background: white;
}

#workflowManager .workflow-header .workflow-heading {
  margin-bottom: 0;
  font-size: 16.5px;
  font-weight: 500;
  color: #090e47;
}

#workflowManager .workflow-input-wrapper {
  height: 35px;
}

#workflowManager .workflow-header .workflow-name-input {
  width: 250px;
  padding-left: 10px;
  font-size: 14px;
  height: 35px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

#workflowManager .workflow-header .close-workflow {
  color: white;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  background: #abaaaa;
  padding: 10px 15px;
  font-weight: 500;
  margin-left: 10px;
  font-size: 15px;
}

#workflowManager .workflow-header .close-workflow-icon {
  margin-right: 5px;
  margin-bottom: 3px;
}

#workflowManager .workflow-header .workflow-button {
  background-color: #3350fc;
  color: white;
  padding: 10px 15px;
  font-size: 15px;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

#workflowManager .workflow-header .workflow-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

#workflowManager .workflow-header .workflow-button svg {
  margin-right: 6px;
}

.workflow-name-error {
  color: red;
  margin-top: -2px;
}

/*workflow sidebar styles*/

#workflowManager .workflow-sidebar {
}
#workflowManager .sidebar-heading {
  width: 100%;
  border-bottom: 1px solid #dfdfe2;
  padding: 10px 20px;
}

#workflowManager .sidebar-heading h3 {
  font-weight: normal;
  margin-bottom: 0;
  font-weight: 500;
  color: #495057;
  font-size: 16px;
}

#workflowManager .workflow-section {
  padding: 10px 15px;
}

#workflowManager .search-container {
  position: relative;
  margin-bottom: 15px;
}

#workflowManager .search-input {
  width: 100%;
  padding: 5px 15px;
  padding-right: 50px;
  border-radius: 8px;
  border: 1px solid #dfdfe2;
  padding-left: 30px;
  height: 44px;
  background: #fafafa;
}

#workflowManager .search-icon {
  position: absolute;
  top: 21px;
  left: 10px;
  transform: translateY(-50%);
  z-index: 20;
}

#workflowManager .clear-btn {
  position: absolute;
  top: 50%;
  right: -2px;
  transform: translateY(-50%);
  background: none;
  border: none;
}

#workflowManager .toggle-btn {
  width: 100%;
  background: white;
  border: 1px solid #dfdfe2;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding: 7px 12px;

  font-family: Poppins;
  line-height: 34px;
  letter-spacing: 0px;

  font-weight: 500;
  color: #495057;
  font-size: 16px;
}

#workflowManager .toggle-btn:last-child {
  margin-bottom: 0;
}

#workflowManager .category-tap {
  display: flex;
  width: 40px;
  height: 40px;
  background: red;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: #1d8131;
}
#workflowManager .category-model {
  display: flex;
  width: 40px;
  height: 40px;
  background: red;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: #5e2852;
}
#workflowManager .category-push {
  display: flex;
  width: 40px;
  height: 40px;
  background: red;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: #0174b2;
}

#workflowManager .category-workflow {
  display: flex;
  width: 40px;
  height: 40px;
  background: red;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: #191989;
}

#workflowManager .dags-list {
  list-style: none;
  padding-left: 10px;
}
#workflowManager .dag-item {
  width: 100%;
  background: white;
  border: 1px solid #dfdfe2;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding: 15px 12px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: normal;
  line-height: 15px;
  letter-spacing: 0px;
  word-break: break-all;
  color: black;
  display: flex;
  align-items: center;
  background-color: rgb(253, 253, 253);
}

#workflowManager .dag-item:hover {
  opacity: 0.8;
  cursor: grab;
}

#workflowManager .dag-item img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 5px;
}

#workflowManager .dag-item p {
  margin-bottom: 0;
}

/* react flow elements*/
#workflowManager .dag-wrapper {
  min-width: 300px;
  max-width: 400px;
  min-height: 90px;
  height: auto;
  background: white;
  color: black;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
}

.dag-connection-net {
  width: 100%;
  height: 100%;
  opacity: 0.01;
  position: absolute;
  top: 0;
  left: 0;
  cursor: crosshair;
}

.status-delete-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.status-tag {
  background-color: black;
  padding: 2px 7px;
  border-radius: 8px;
  color: white;
  display: block;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.delete-button {
  width: 29px;
  height: 29px;
  border: 1px solid #a1a5c6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

.dag-details {
  display: flex;
  justify-content: start;
}

.dag-icon {
  width: 44px;
  height: 44px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 7.5px;
}

.dag-icon img {
  width: 35px;
  height: 35px;
  -o-object-fit: contain;
  object-fit: contain;
}

.dag-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 60px);
}

.dag-data .dag-label {
  margin-bottom: 0;
  color: #171725;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  word-break: break-all;
  text-align: left;
}

.last-run-wrapper {
  display: flex;
}

.last-run-wrapper p {
  color: #a1a5c6;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: 0;
  margin-left: 5px;
}

.history-dag-button {
  color: #1e293b;
  cursor: pointer;
  margin-left: auto;
  margin-right: 5px;
  background-color: transparent;
  width: 26px;
  height: 26px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid #e2e8f0;
  position: relative;
  overflow: hidden;
}

.history-dag-button:hover {
  border-color: #cbd5e1;
}

.history-dag-button:active {
  transform: scale(0.95);
}

.history-dag-button svg {
  transition: all 0.2s ease;
}

.history-dag-button:hover svg {
  transform: scale(1.15);
  color: #0f172a;
}

.lighter-hover:hover {
  opacity: 0.9;
}

a[href="https://reactflow.dev"]
{
  display: none;
}

/* I hope I delete this one day  - no second WF header - MAX changes requests */

.workflow-wrapper {
  margin: 0;
}

.workflow-wrapper #workflowManager {
  border-top: none;
}

.workflow-wrapper > div.card {
  margin-bottom: 0;
  border-top: none !important;
}

.workflow-wrapper #workflowManager .workflow-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfe2;
  background: #f1f2f6;
  padding: 5px 0 7px 0;
  height: unset;
}

.vis-header {
  padding: 13px 0px 10px 0px;
  font-size: 16.5px;
  border-bottom: 1px solid rgb(204, 204, 204);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vis-wrapper {
  min-height: 500px;
  height: 100%;
  background: transparent;
  overflow: hidden;
  border-top: none;
}

.vis-wrapper .canvas-wrapper {
  border-right: 1px solid rgb(204, 204, 204);
}

.card.vis-wrapper {
  box-shadow: none;
}
.workflow-vis-wrapper {
  margin: 0px 3px -9px 3px;
  padding: 0;
}

/*Lineage css*/

#workflowManager.Lineage-Vis {
  margin: 20px 10px;
}
#workflowManager.Lineage-Vis .canvas-wrapper {
  border: 1px solid #dfdfe2;
}
