* {
  box-sizing: border-box;
}
.w-embed::after,
.w-embed::before {
  content: " ";
  display: inline-block;
  grid-area: 1 / 1 / 2 / 2;
  width: 20%;
}
.w-embed::after {
  display: inline-block;
  width: 20%;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0px;
}
input {
  line-height: normal;
}
.card-taps {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 20%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(26, 25, 77, 0.12) 0px 2px 4px 0px;
  transition: box-shadow 0.3s ease 0s;
  color: rgb(26, 25, 77);
  text-decoration: none;
  margin-bottom: 25px;
  cursor: pointer;
}
.card-taps:hover {
  box-shadow: rgba(26, 25, 77, 0.2) 0px 27px 12px -17px,
    rgba(26, 25, 77, 0.12) 0px 4px 8px 0px;
}
.card-taps-body {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 24px;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  padding-bottom: 25px;
}
.card-taps-body.connector-card-taps-body {
  height: 100%;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
}
a {
  background-color: transparent;
  color: rgb(27, 40, 116);
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0px;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.connector-card-taps-link {
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  transition: color 0.3s ease 0s;
  color: rgb(26, 25, 77);
}
.connector-card-taps-link:hover {
  color: rgb(16, 10, 46);
}
.connector-image {
  position: relative;
  display: flex;
  width: 125px;
  height: 75px;
  margin-bottom: 16px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  object-fit: fill;
}
img {
  border: 0px;
  vertical-align: middle;
  display: inline;
  max-width: 100%;
}
.image-contain {
  object-fit: cover;
}
.text-center {
  text-align: center;
}
.card-taps-title {
  position: relative;
  margin-bottom: 0px;
  line-height: 28px;
  font-weight: 600;
}
.mb-5 {
  margin-bottom: 5px;
}
.card-taps-metadata {
  -webkit-box-align: center;
  align-items: center;
  color: rgb(175, 175, 193);
  font-size: 14px;
  line-height: 28px;
}
.icons-wrapper {
  position: absolute;
  inset: 11px 8px auto auto;
}
.w-condition-invisible,
.w-dyn-bind-empty,
.w-dyn-hide {
  margin-bottom: 25px;
  display: inline-block;
  width: 20%;
}
.company-name-text {
  display: inline;
  color: rgb(175, 175, 193);
  font-size: 10px;
  line-height: 120%;
  font-style: italic;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .card-taps-body {
    padding: 16px;
  }
}
@media screen and (max-width: 479px) {
  .card-taps-body {
    padding: 12px;
  }
}

/* .form-tab-enter {
  opacity: 0.7;
}

.form-tab-enter-active {
  opacity: 1;
  transition: opacity 50ms, transform 300ms;
} */

.form-tab-exit {
  opacity: 1;
}

.form-tab-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
