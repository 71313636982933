.schema-list {
  border-radius: 0px;
  width: 100%;
  background-color: #ffffff;
  border: 1.2px solid #dfe0eb;
  overflow-y: auto;
}

.schema-list::-webkit-scrollbar-track,
.queries-box-details::-webkit-scrollbar-track,
.custom-dropdown-menu::-webkit-scrollbar-track {
  background-color: #f1f1f0;
  border-radius: 10px;
}

.schema-list::-webkit-scrollbar,
.queries-box-details::-webkit-scrollbar,
.custom-dropdown-menu::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f1f1f0;
}

.schema-list::-webkit-scrollbar-thumb,
.queries-box-details::-webkit-scrollbar-thumb,
.custom-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(90deg,
    #c1c1c1 0%,
    #c1c1c1 25%,
    transparent 100%,
    #c1c1c1 75%,
    transparent)
}

.borders-editor {
  max-height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 1.2px solid #dfe0eb;
  border-radius: 0px;
  overflow-y: auto;
  border-bottom: none;
  border-top-left-radius: 0rem;
}

.schema-list-half {
  height: 49.9%;
  width: 10%;
  overflow-y: auto;
}

.schema-list-half {
  border-radius: 0px;
  max-height: 49.9%;
  width: 100%;
  background-color: #ffffff;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border: 1.2px solid #dfe0eb;
  overflow-y: auto;
}

.schema-list-minimized {
  height: 30px;
  width: 10%;
  overflow-y: auto;
}

.schema-list-minimized {
  border-radius: 0px;
  max-height: 30px;
  width: 100%;
  background-color: #ffffff;
  border: 1.2px solid #dfe0eb;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  overflow-y: auto;
}
