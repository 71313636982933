.table-daged {
  margin-top: 0 !important;
  width: calc(100% - 16px) !important;
}

table.table-daged {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 3px;
}

table.table-lists.table-daged {
  border-width: 0 !important;
}

table.table-lists.table-daged > :not(caption) > * > * {
  border-width: 0 !important;
}

table.table-lists.table-daged > :not(caption) > * {
  outline: 1px solid #ebebeb;
  outline-offset: -1px;
  background-color: white;
  border-radius: 5px;
  height: 65px;
}

table.table-daged thead tr {
  outline: none !important;
  background-color: transparent !important;
  height: unset !important;
}

.table-daged.table-lists th {
  color: #448593 !important;
  text-transform: capitalize;
  min-width: 50px;
}

.table-daged th.resizables {
  resize: horizontal;
  overflow: hidden;
}

.table-daged .react-resizable-handle-e {
  right: 2px;
  bottom: -2px;
  top: unset;
}

.table-daged .react-resizable-handle-e {
  display: block;
  width: 1em;
  height: 1em;
}

.table-daged .react-resizable-handle-e:hover {
  background-color: transparent;
}

.table-daged .react-resizable-handle-e::before {
  content: "";
  position: absolute;
  border-bottom: .1rem solid currentColor;
  height: 1.4em;
  transform: translate(-84%, -84%) rotate(-45deg); 
  width: 1.4em;
}
.table-daged .react-resizable-handle-e::after {
  content: "";
  position: absolute;
  border-bottom: .1rem solid currentColor;
  height: .7em;
  transform: translate(-50%, -50%) rotate(-45deg); 
  width: .7em;
}

.table-daged.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: rgb(250, 250, 250) !important;
}

.table-daged .frequency-select {
  max-width: 150px;
  margin: 0;
}

.table-daged .frequency-select > div {
  border: none;
}

.table-daged .frequency-select > div > div > span {
  display: none;
}

.btn-generaltabs.wf-tabs {
  border: 1px solid #f1f1f1;
  color: #448593;
}

.btn-generaltabs.wf-tabs.active {
  background-color: #cbdcf2;
  color: #090e47;
  border: none;
}

.table-daged .rounded-table-logo {
  max-width: 50px;
  max-height: 50px;
  width: 40px;
  height: 40px;
  background-color: #f1f1f1;
  border-radius: 50%;
  object-fit: contain;
  padding: 5px;
}

table.table-daged thead th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  z-index: 99;
  background: #f8f9fc !important;
  line-height: 15px !important;
  font-size: 11px !important;
}

table.table-daged.no-sticky-table-header thead th {
  position: relative;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-daged .rotate-path,
.rotate-path {
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

.table-daged .dag-folder-manger td {
  padding: 0 5% 0 6% !important;
}

.table-daged .dag-folder-manger div {
  margin: 10px 0;
  height: 30px;
  padding-top: 5px;
  display: flex;
}

.table-daged .dag-folder-manger div div {
  margin-top: -5px;
}

.table-daged .dag-folder-manger div span {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 11px;
}

.table-daged .dag-folder-manger div span p {
  margin: 0;
}

.table-daged .dag-folder-manger div + div {
  border-top: 1px solid #ebebeb;
}

.table-daged .file-data {
  border-top: none !important;
  padding: 0;
}

.table-daged .centered-svg-block,
.centered-svg-block {
  display: block;
  margin: 0 auto;
  width: 20px;
}

#th-name {
  z-index: 120;
}

#th-workflows {
  z-index: 110;
}

.my-8px {
  margin-right: 8px !important;
  margin-left: 8px !important;
}
