.sidebar-modal-wrapper {
  position: fixed !important;
  top: 50px;
  right: 0;
  width: 600px;
  background-color: white;
  z-index: 999;
  border: 2px solid #ebebeb;
  border-radius: 10px 0px 0px 10px;
  background: white;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.test-button {
  height: 34px; /* Adjust the height as needed */
  padding: 0 12px; /* Adjust the padding for proper spacing */
  display: flex;
  align-items: center;
}

.sidebar-modal-wrapper .schedule-sidebar-content {
  overflow: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.heading-schedule-model {
  color: black;
  font-size: 18px;
  margin-bottom: 28px;
}

.collapse-schedule {
  width: 100%;
  background-color: white;
  color: grey !important;
  border: 1px solid #ebebeb;
  color: grey;
  text-align: left;
  margin-bottom: 15px;
  height: 36px;
}

.collapse-schedule-chosen {
  color: #495057 !important;
}

.collapse-schedule:hover {
  background-color: #ebebeb;
  color: black !important;
}

.sidebar-modal-wrapper label {
  color: #4e4f61;
  margin-bottom: 4px;
}

.schedule-menu {
  width: 100%;
  padding: 5px 0;
  border: 1px solid #ebebeb;
  list-style: none;
  border-radius: 5px;
  position: absolute; /* Ensure absolute positioning */
  background: white;
  z-index: 99999;
  top: 38px;
  left: 0;
}

.schedule-val {
  white-space: nowrap;
  overflow: hidden;
}

ul hr.menu-divider {
  background: grey;
  margin: 0;
}

.link-lineage {
  color: #2d49fd;
}

.schedule-menu-item {
  padding: 10px 15px;
  width: 100%;
  cursor: pointer;
}

.schedule-menu-item:hover {
  background-color: #ebebeb;
  font-weight: 500;
}

.sidebar-modal-wrapper #workflowManager.Lineage-Vis {
  margin: 0 !important;
  margin-bottom: 20px !important;
}

.schedule-model-btns-wrapper .sc-btn:hover {
  background-color: #54cb75;
}

.schedule-model-btns-wrapper button:hover {
  opacity: 0.8;
}

.schedule-model-btns-wrapper button:disabled {
  background-color: gray;
}

.schedule-model-btns-wrapper .sc-btn:disabled {
  background-color: gray !important;
}

.test-btn,
.create-button {
  background-color: #2d49fd;
  border: none;
  padding: 12px 16px;
}

.create-button {
  padding: 6px 10px;
}

.sc-btn {
  background-color: #54cb75;
  border: none;
  padding: 12px 16px;
}

.sidebar-modal-wrapper button .button-icon {
  margin-right: 5px;
}

.sidebar-modal-wrapper input {
  border: 1px solid #ebebeb;
}

.sidebar-modal-wrapper .react-resizable {
  position: relative;
}
.sidebar-modal-wrapper .react-resizable-handle {
  position: absolute;
  width: 5px;
  height: 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.sidebar-modal-wrapper .react-resizable-handle-w,
.sidebar-modal-wrapper .react-resizable-handle-e {
  cursor: ew-resize;
}
.sidebar-modal-wrapper .react-resizable-handle-w {
  left: 0;
  top: 0;
}

.sidebar-modal-wrapper .schedule-sidebar-content .lineage-vis-height {
  height: calc(100vh - 550px);
}

.pull-request-modal .accordion-collapse {
  height: calc(100% - 68px);
}

.sidebar-modal-wrapper table.table-daged thead th {
  background-color: white !important;
  position: unset;
}

.sidebar-modal-wrapper table.table-daged {
  margin: 0 !important;
  width: 100% !important;
}

.sidebar-modal-wrapper table + div {
  margin: 20px 0 !important;
}

.dq-btn {
  background-color: #2d49fd;
  border: none;
  padding: 12px 16px;
}

.dq-btn:hover {
  background-color: #1e3af7;
}

.dq-btn:disabled {
  background-color: #a0a0a0 !important;
}

.accordion .accordion {
  margin-left: 1rem;
}

.accordion .card-header {
  padding: 0.5rem 1rem;
}

.accordion .accordion .card-header button {
  padding: 0;
  text-decoration: none;
}

.accordion .accordion .card-body {
  padding: 0.5rem;
}

.custom-accordion .accordion-item {
  border: none;
  background-color: transparent;
}

.custom-accordion .accordion-header {
  padding: 0;
}

.custom-accordion .accordion-button {
  padding: 0.5rem 0;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  text-align: left;
}

.custom-accordion .accordion-button::after {
  margin-left: auto;
}

.custom-accordion .accordion-button > div {
  flex-grow: 1;
  text-align: left;
}

.custom-accordion .accordion-body {
  padding: 0.5rem 0 0.5rem 1rem;
}

.custom-accordion .accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
}

.custom-accordion .accordion-body > div:last-child {
  margin-bottom: 0 !important;
}

.custom-accordion-item {
  margin-bottom: 0.5rem;
}

.custom-accordion-item:last-child {
  margin-bottom: 0;
}

.custom-accordion-item hr {
  margin: 0.5rem 0;
  opacity: 0.2;
}

.custom-accordion .custom-accordion {
  margin-left: 1rem;
}

.custom-accordion .custom-accordion .accordion-button {
  font-size: 0.9rem;
  padding: 0.25rem 0;
}

.custom-accordion .custom-accordion .accordion-body {
  padding: 0.25rem 0 0.25rem 1rem;
}

.custom-accordion .form-label {
  margin-bottom: 0.25rem;
}

.custom-accordion .form-control {
  font-size: 0.875rem;
}

.custom-accordion {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
}

.custom-accordion .accordion-button {
  font-size: 14px;
  padding: 0.5rem 0;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  text-align: left;
}

.custom-accordion .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.custom-accordion .accordion-button::after {
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
}

.custom-accordion .accordion-body {
  padding: 0.5rem 0 0.5rem 1rem;
}

.custom-accordion .form-label {
  font-size: 12px;
}

.custom-accordion .form-control {
  font-size: 12px;
}

.custom-accordion .custom-accordion .accordion-button {
  font-size: 13px;
}

.custom-accordion .custom-accordion .form-label {
  font-size: 13px;
}

.custom-accordion .custom-accordion .form-control {
  font-size: 13px;
}

.create-test-box {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 1rem;
}

.create-test-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: #495057;
}

.create-test-box .dropdown-toggle {
  font-size: 13px;
}

.create-test-box .dropdown-menu {
  font-size: 13px;
}

.create-test-box .dropdown-item {
  padding: 0.25rem 1rem;
}

.fixed-width-input {
  width: 50% !important; /* Adjust this value as needed */
  max-width: 50% !important;
}

.common-label {
  flex-grow: 1;
  text-align: left;
}
