.create-folder-btn {
  display: flex;
  align-items: center;
  padding: 3px 6px;
  border-radius: 10px;
  border: 1.5px solid #2952ac;
  color: #2952ac;
  font-weight: 500;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.create-folder-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: #f8f9fa;
}

.create-folder-btn svg {
  width: 16px;
  height: 16px;
  stroke: currentColor;
}

.token.comment.existing-code {
  color: #888 !important;
  font-style: italic;
}
