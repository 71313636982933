.lighter-hover:hover {
  opacity: 0.9;
  box-shadow: none;
}

.transparent-bg {
  background: transparent !important;
}

.tooltip {
  z-index: 999999;
}

.full-width {
  width: 100%;
}
