.help-tooltip {
  position: absolute;
  right: 0;
  height: -moz-max-content;
  height: max-content;
  background-color: white;
  color: #4e4f61;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  z-index: 9999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #dfe0eb;
  pointer-events: none;
  display: block;
  opacity: 1;
}

.help-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.floating-button {
  position: absolute;
  right: 6px;
  z-index: 989;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  background-color: #2c49fc !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 2px 12px rgba(44, 73, 252, 0.3) !important;
  transition: all 0.3s ease !important;
}

.floating-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 16px rgba(44, 73, 252, 0.4) !important;
  background-color: #2440e3 !important;
}

.floating-button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #2c49fc;
  animation: pulse 2s infinite;
  box-sizing: border-box;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  70% {
    transform: scale(1.3);
    opacity: 0;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.floating-button svg {
  color: white !important;
  width: 20px !important;
  height: 20px !important;
}

.message-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px 15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.user-message, .gpt-message {
  padding: 12px 16px;
  border-radius: 8px;
  max-width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.user-message {
  align-self: flex-end;
  background-color: #5a5d88;
  color: white;
  border-bottom-right-radius: 2px;
  border-color: #090f47;
  margin-right: 0;
}

.gpt-message {
  align-self: flex-start;
  background-color: white;
  color: #333;
  border-bottom-left-radius: 2px;
  border-color: #e0e0e0;
  margin-left: 0;
  max-width: calc(100% - 16px);
}

.message-text {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

/* Preserve code block styling */
.gpt-message .code-block-wrapper {
  margin: 0px 0 -5px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

/* Adjust typing indicator to match new style */
.gpt-message .typing-indicator {
  min-height: 24px;
  display: flex;
  align-items: center;
}

.datajolt-input,
.datajolt-send {
  height: 38px; /* Adjust this value to set the desired height */
}

.message-input-form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
  gap: 8px;
  padding: 10px;
}

.message-input-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.datajolt-input {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  font-family: var(--bs-body-font-family);
  font-size: 12px;
  font-weight: 300;
  color: #334155;
  min-height: 42px;
  line-height: 1.5;
  padding: 10px 16px;
  background-color: #ffffff;
}

.datajolt-input:hover {
  border-color: #cbd5e1;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.datajolt-input:focus {
  border-color: #cbd5e1;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  outline: none;
}

.datajolt-input:active {
  background-color: #f8fafc;
  transform: translateY(1px);
}

.datajolt-input::placeholder {
  color: #a1a5c6;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
}

.datajolt-send {
  height: 42px;
  min-width: 42px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datajolt-send:hover {
  background-color: #16a085;
}

.offcanvas-header {
  background-color:#090F47; /* Datajolt sidebar blue from index.css line 28 */
  color: #fff;
}

.loading-dots {
  display: inline-block;
  margin-left: 4px;
}

.loading-dots span {
  animation: blink 1.4s infinite;
  animation-fill-mode: both;
  margin-left: 2px;
}

.loading-dots span:nth-child(2) {
  animation-delay: .2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: .4s;
}

@keyframes blink {
  0% { opacity: .2; }
  20% { opacity: 1; }
  100% { opacity: .2; }
}

/* Code block styling */
.message-text pre {
  background-color: #ffffff !important;
  padding: 15px;
  border-radius: 8px;
  margin: 0;
  border: 1px solid #ebebeb;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.message-text pre code {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", monospace;
  font-size: 10.5px !important;  /* Reduced from 12px to 11px */
  line-height: 15px !important;  /* Adjusted line height to match smaller font */
  background-color: transparent;
  padding: 0;
  color: #000000 !important;
  display: block;
  white-space: pre-wrap;
}

/* Inline code styling */
.message-text code:not(pre code) {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Consolas", monospace;
  font-size: 13px;
  background-color: #f8f9fa;
  padding: 2px 4px;
  border-radius: 4px;
  color: #090E47;
}

.user-message .message-text {
  color: white;
}

/* Keep GPT message text color as is */
.gpt-message .message-text {
  color: #171725;
}

/* Code block container */
.code-block-wrapper {
  position: relative;
  margin: 2px 0;
}

/* Code actions container */
.code-actions {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 4px;
  z-index: 1;
}

/* Code action buttons */
.code-action-btn {
  background-color: #4884ec;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.code-action-btn:hover {
  background-color: #3670d8;
  color: white;
}

.code-action-btn:disabled {
  background-color: #a5bef0;
  cursor: not-allowed;
}

.code-action-btn.running {
  background-color: #a5bef0;
  cursor: not-allowed;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  color: white;
}

/* Adjust code block padding to accommodate buttons */
.code-block-wrapper pre {
  padding-top: 45px;
}

/* Update existing code block styling */
.message-text pre {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin: 0;
  border: 1px solid #ebebeb;
  overflow-x: auto;
}

/* Update loading message style */
.gpt-message.typing {
  background-color: #f8f9fa;
  color: #6c757d;
  font-style: italic;
}

.chat-link {
  color: #2c49fc;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.link-icon {
  font-size: 12px;
  opacity: 0.8;
}

.chat-link:hover {
  text-decoration: underline;
  color: #2c49fc;
}

.chat-link:hover .link-icon {
  opacity: 1;
}

/* Make sure links in user messages are visible */
.user-message .chat-link,
.user-message .link-icon {
  color: white;
}

.user-message .chat-link {
  text-decoration: underline;
}

.user-message .chat-link:hover {
  color: #f0f0f0;
}

/* Make bold text more prominent */
.message-text b {
  font-weight: 750;
  color: inherit;
}

/* Ensure bold text is visible in both message types */
.user-message .message-text b {
  color: white;
}

.gpt-message .message-text b {
  color: #171725;
}

/* SQL Syntax Highlighting - Matching Monaco Editor's light theme */
.token.keyword {
  color: #0000ff !important;  /* Blue for SQL keywords like SELECT, FROM, WHERE */
}

.token.function {
  color: #c404c4 !important;  /* Brown for function names like SUM, COUNT */
}

.token.operator {
  color: #000000 !important;  /* Black for operators */
}

.token.number {
  color: #098658 !important;  /* Green for numbers */
}

.token.string {
  color: #fc0807 !important;  /* Red for strings */
}

.token.comment {
  color: #008000 !important;  /* Green for comments */
}

.token.variable,
.token.column {
  color: #001080 !important;  /* Dark blue for column names */
}

.token.type {
  color: #267f99 !important;  /* Teal for data types */
}

/* Code block styling */
.code-block-wrapper {
  background: #ffffff;  /* White background */
  border-radius: 4px;
  margin: 8px 0 16px 0;  /* Added bottom margin */
  border: 1px solid #e0e0e0;  /* Light gray border */
}

.code-block-wrapper pre {
  margin: 0;
  padding: 12px;
  background: transparent;
}

.code-block-wrapper code {
  font-family: 'Consolas', 'Monaco', monospace;
  font-size: 14px;
  line-height: 1.4;
  color: #000000;  /* Default text color */
}

.typing-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background-color: #438593;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% { 
    transform: scale(0);
  }
  40% { 
    transform: scale(1);
  }
}

/* Update header padding to match */
.header-container {
  padding: 0 15px;
}

.conversation-history {
  padding: 0 15px;
  height: calc(100% - 120px);
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.conversation-history .d-flex {
  flex: 1;
}

.conversation-history .list-group-item {
  border: 1px solid #dee2e6 !important;
  border-radius: 8px !important;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
  padding: 12px 15px;
}

.conversation-history .list-group-item:hover {
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border: 1px solid #dee2e6 !important;
}

.conversation-history h6 {
  font-size: 0.875rem;
  margin: 0;
  color: #344054;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.conversation-history small {
  font-size: 0.75rem;
}

/* Add these CSS classes */
@keyframes pulse-border {
  0% {
    border-color: #dee2e6;
  }
  35% {
    border-color: #100c44;
  }
  65% {
    border-color: #100c44;
  }
  100% {
    border-color: #dee2e6;
  }
}

.list-group-item.loading {
  animation: pulse-border 1s infinite;
  border-width: 2px !important;
  border-style: solid !important;
}

/* Add hover state that doesn't interfere with animation */
.list-group-item.loading:hover {
  border-color: #100c44;
  background-color: rgba(16, 12, 68, 0.05);
}

/* Style for the loading spinner */
.loading-spinner {
  width: 16px !important;
  height: 16px !important;
  border-width: 2px !important;
  color: #6e6e6e !important;
  margin: auto 0; /* Vertical centering */
}

/* Update the header small element styling */
.heading-schedule-model small {
  width: 100%;  /* Take full width */
  font-weight: 500;
  font-size: 12px;
  border: 2px solid #D0D5DD;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  height: 48px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;  /* Remove any margin */
}

/* Update list group to remove default margins */
.list-group {
  margin: 0;
  padding: 0;
  border: none !important;
}

.list-group-item:first-child {
  border: 1px solid #dee2e6 !important;
  margin-top: 0;
}

.list-group-item:first-child:hover {
  border: 1px solid #dee2e6 !important;
}

/* Hide message input when showing history */
.conversation-history + .message-input-form {
  display: none !important;
}

/* Update spinner container alignment */
.list-group-item small.text-muted {
  display: flex;
  align-items: center;
  min-height: 24px; /* Match the height of the timestamp text */
}

.modern-button {
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.modern-button:hover {
  background: #f8f9fa;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modern-button.history-button,
.modern-button.new-chat-button,
.modern-button.history-button:active,
.modern-button.history-button:focus,
.modern-button.new-chat-button:active,
.modern-button.new-chat-button:focus {
  color: #438593 !important;
}

.history-button {
  color: #438593;
}

.new-chat-button {
  color: #438593;
}

.modern-button:active,
.modern-button:focus,
.modern-button.active,
.modern-button:focus-visible {
  background: white !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-color: #e0e0e0 !important;
  outline: none !important;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active {
  background-color: white;
  border-color: #e0e0e0;
}

/* Make sure code blocks in both message types use these colors */
.user-message .message-text code,
.gpt-message .message-text code {
  color: inherit;  /* Reset color inheritance */
}

/* Ensure the code block background doesn't interfere with syntax highlighting */
.message-text pre {
  background-color: #ffffff !important;
}

.message-text pre code {
  color: #000000 !important;  /* Default text color for code blocks */
}

.delete-button {
  opacity: 0;
  transition: opacity 0.2s ease, color 0.2s ease;
  margin-left: 8px;
  color: #6c757d; /* Default color */
}

.delete-button:hover {
  color: #ff3b30 !important; /* Brighter red on hover */
}

.list-group-item:hover .delete-button {
  opacity: 1;
}

/* Ensure the button doesn't affect the layout when hidden */
.delete-button svg {
  display: block;
}

/* Add animation for the typing message border */
@keyframes border-pulse {
  0% {
    border-color: #e0e0e0;
  }
  50% {
    border-color: rgba(232, 251, 255, 0.489); /* Even more transparent version of #438593 */
  }
  100% {
    border-color: #e0e0e0;
  }
}

/* Update the typing message style */
.gpt-message .typing-indicator {
  min-height: 24px;
  display: flex;
  align-items: center;
}

/* Add animation to the parent message box when typing */
.gpt-message:has(.typing-indicator) {
  animation: border-pulse 1.7s infinite ease-in-out; /* Even slower animation */
  border-width: 1.1px; /* Standard border width */
}

/* YAML Syntax Highlighting - Matching Monaco Editor's colors */
.language-yaml .token.value,
.language-yaml .token.string,
.language-yaml .token.description {
  color: #0451a5 !important;  /* Monaco's blue */
}

.language-yaml .token.comment {
  color: #808080 !important;  /* Grey for comments */
  font-style: italic !important;
}

.language-yaml .token.key,
.language-yaml .token.property {
  color: #318f8d !important;  /* Monaco's blue for keys */
}

.language-yaml .token.punctuation {
  color: #000000 !important;  /* Black for colons and other punctuation */
}

.language-yaml .token.number {
  color: #098658 !important;  /* Monaco's color for numbers */
}

.language-yaml .token.boolean {
  color: #0000ff !important;  /* Monaco's color for booleans */
}

.language-yaml .token.variable,
.language-yaml .token.column {
  color: #001080 !important;  /* Dark blue for column names */
}

.language-yaml .token.type {
  color: #267f99 !important;  /* Teal for data types */
}

