.container {
  display: flex;
  height: 100%;
  max-width: 100%;
  height: 100%;
}

/* Add styles to avoid flicker on page load */
.actions-helper-tabs-editor button {
  color: #4e4f61;
  text-decoration: none;
  font-size: 12px;
  font-family: var(--bs-body-font-family) !important;
  font-weight: 400;
  margin-left: -10px;
  transition: none; /* Ensure no transition to avoid flicker */
}

.MuiTableCell-root {
  font-family: var(--bs-body-font-family) !important;
}

.MuiTableCell-body {
  color: inherit !important;
}

.draggable-modal .modal-dialog {
  width: 90%;
  max-width: none;
  margin: 0;
}

.draggable-modal .modal-content {
  height: 90vh;
}

.draggable-modal .modal-header {
  cursor: move;
}

.actions-helper-tabs-editor button.active-result-tab {
  color: #2d49fd !important;
  transition: none; /* Ensure no transition to avoid flicker */
}

.actions-helper-tabs-editor button:hover {
  text-decoration: none !important;
}

/* Optionally, you can disable transitions initially and enable them after load */
.actions-helper-tabs-editor {
  opacity: 1;
  transition: opacity 0.3s;
}

.actions-helper-tabs-editor.loaded {
  opacity: 1;
}

.left-panel {
  flex: 1;
  padding: 10px;
  padding-top: 20px;
  border-right: 2px solid #ddd;
  overflow-y: auto;
}

.right-panel {
  flex: 2;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.run-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.run-item:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.run-item.selected {
  background-color: #E1EDF9;
  border-right: 4px solid #3F80EA;
  padding-right: 8px; /* Reduce right padding to compensate for border */
  cursor: pointer;
}

.run-status-icon {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
  color: inherit;
}

.run-command-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 0;
  position: relative;
}

/* Ensure native tooltips display properly */
.run-command-text[title] {
  cursor: pointer;
}

.cli-wrapper {
  margin: 10px 10px 0px 8px;
  width: calc(100% - 18px);
  display: flex;
  align-items: center;
}

.log-display {
  white-space: pre-wrap;
  background-color: #0d0909;
  padding: 10px;
  border-radius: 5px;
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
}

.cli-wrapper .input-group {
  flex-grow: 1;
}

.editor-tab-container::-webkit-scrollbar {
  display: none;
}

.btn-group > .btn {
  flex: none;
  text-align: left;
}

.btn-group > .btn:last-child {
  margin-right: calc(8%);
}

.new-file-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
}

.editior-tab-container {
  height: 100%;
  width: 100%;
  overflow-x: scroll !important;
  white-space: nowrap;
}

.editor-tab-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: center;
  z-index: 2;
  margin-left: auto;
}

.editior-tab-container-wrapper {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  /* height: 28px; */
  overflow: hidden;
}

.editior-tab-container-wrapper-inner {
  width: 100%;
  position: relative;
}

.editior-tab-container-wrapper-inner:after {
  content: "";
  height: 100%;
  width: 8%;
  background: linear-gradient(-90deg, #f8f9fc 50%, rgba(255, 255, 255, 0) 80%);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.create-file-tab-button {
  position: absolute;
  right: 0;
  z-index: 99;
  color: black;
  margin-top: 6px;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 100%;
  height: 5px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  padding: 0 3px 3px 0;
  background: transparent;
}

.react-resizable-handle:hover {
  background-color: #cfd0d8;
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 0;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  z-index: 2000;
}

.react-resizable-handle-e {
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  cursor: ew-resize;
  z-index: 1000;
}

.react-resizable-handle-w {
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  cursor: ew-resize;
  z-index: 500;
}

section.editor-actions-box {
  border-bottom: 1.2px solid #dfe0eb;
  background-color: white;
  padding: 10px 10px;
  display: flex;
  gap: 10px;
  z-index: 101;
}

.actions-helper-tabs-editor {
  display: flex;
  gap: 5px;
}

.actions-helper-tabs-editor button {
  color: #4e4f61;
  text-decoration: none;
  font-size: 12px;
  font-family: var(--bs-body-font-family) !important;
  font-weight: 400;
  margin-left: -10px;
}

.actions-helper-tabs-editor button:hover {
  text-decoration: none !important;
}

.active-result-tab {
  color: #2d49fd !important;
}

.cli-wrapper {
  /* gap: 10px; */
}

#input-query-folder-dropdown {
  width: 60px;
}

.trigger-cli.btn {
  width: 40px;
}

.card .log-display {
  height: 383px !important;
  margin: 20px 10px;
}

.dragged-box {
  background-color: white;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 990;
  /* -webkit-box-shadow: 0px 0px 3px 2px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px 0px 3px 2px rgba(214, 214, 214, 1);
  box-shadow: 0px 0px 3px 2px rgba(214, 214, 214, 1); */
  /* border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; */
  border: 1.2px solid #dfe0eb;
}

#panel1a-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.queries-box {
  background-color: white;
  overflow: hidden;
  z-index: 999;
  border: 1.2px solid #dfe0eb;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  left: 5px;
  bottom: 1px;
  max-height: 95%;
  width: calc(100% - 10px);
}

.queries-box .react-resizable-handle-n {
  top: 0;
  width: 100%;
  transform: unset;
  height: 5px;
  left: 0;
  background: none;
}

.queries-box .MuiTreeItem-label,
.schema-list-half .MuiTreeItem-label {
  font-size: 13px !important;
}

.hover-handles .react-resizable-handle {
  display: none;
}
.hover-handles:hover .react-resizable-handle {
  display: block;
}
.absolutely-positioned {
  position: absolute !important;
}
.left-aligned {
  left: 0;
}
.bottom-aligned {
  bottom: 0;
}

.custom-box {
  overflow: visible;
}

.search-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.search-input--higher {
  margin-top: -2px;
}

.search-input-icon {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 4px;
}

.search-input-icon-prefix {
  left: 8px;
}

.search-input-icon-suffix {
  right: 8px;
  cursor: pointer;
}

.search-input-field {
  padding: 4px 20px;
  width: 100%;
  margin: 8px 8px;
}

.editor-tab-container {
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
}

.editor-tab-container button.btn,
.editor-tab-container .dragged-tab {
  border: none !important;
  background-color: transparent !important;
  position: relative;
  top: 1px;
  height: calc(100% + 2px);
  color: grey;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 400;
}

.editor-tab-container .dragged-tab:last-of-type {
  margin-right: 80px;
}

.editor-tab-container button.btn:hover,
.editor-tab-container .dragged-tab:hover {
  outline: 1px solid #dfe0eb !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom: none !important;
  background-color: white !important;
}

.editor-tab-container button.btn.active,
.editor-tab-container button.action-buttons-editor,
.editor-tab-container .dragged-tab.active {
  border: 1px solid #dfe0eb !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom: none !important;
  background-color: white !important;
  color: black;
}

.new-file-container > button.btn,
.new-file-container > button.btn:hover {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.editor-tab-container button.action-buttons-editor {
  top: 0;
}

.editor-tab-container button.btn .custom-branch-prod-pill {
  background-color: rgb(201, 201, 201);
}

.editor-tab-container button.active .custom-branch-prod-pill {
  background-color: #2d49fd;
}

.editor-tab-container button.btn .custom-branch-dev-pill {
  background-color: rgb(201, 201, 201);
}

.editor-tab-container button.active .custom-branch-dev-pill {
  background-color: grey;
}

.helper-list.Mui-expanded {
  margin: 0 !important;
}

.helper-list .MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0 !important;
  min-height: unset !important;
}

.helper-list .MuiAccordionSummary-root.Mui-expanded {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: white;
  z-index: 99;
}

#panel1a-header {
  padding: 0 5px;
}

.helper-list .MuiTreeItem-label {
  font-size: 11px !important;
  font-weight: 400 !important;
  font-family: var(--bs-body-font-family) !important;
  color: #495057;
}

.helper-list .MuiAccordionSummary-content {
  margin: 10px 0 !important;
}

.helper-list:first-of-type {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.helper-list:last-of-type {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.helper-list .timeline:before {
  background: #448593;
  opacity: 0.5;
}

.helper-list .timeline-item-selected:before,
.helper-list .timeline-item:before {
  border: 0;
  background: #448593;
  left: 5px;
  width: 10px;
  height: 10px;
}

#tooltip-bottom-helper-box.tooltip .tooltip-inner {
  background: #448593;
}

#tooltip-bottom-helper-box .tooltip-arrow::before {
  border-bottom-color: #448593 !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.helper-editor-resizable .react-resizable-handle-n {
  cursor: ns-resize;
  top: 1px;
  z-index: 99999;
  /* width: calc(100% - 11px); */
  left: 50%;
  transform: translate(-50%, -50%);
}

.helper-editor-resizable {
  margin-top: -2px;
}

.no-resize .react-resizable-handle {
  display: none !important;
}

.branch-dropdown {
  display: flex;
  align-items: center;
}

.branch-dropdown-name {
  font-size: 12px;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-inline-end: 8px;
}

.locked-branch-btn {
  margin-inline-start: auto;
  padding: 0;
  border: 0;
  background: #cbceea;
  color: #0b1b397a;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.delete-branch-btn {
  margin-inline-start: auto;
  padding: 0;
  border: 0;
  background: #f3f3f3;
  color: #d9534f;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.delete-branch-btn:hover {
  color: #f3f3f3;
  background-color: #d9534f;
}

.monaco-editor .suggest-widget {
  z-index: 1000 !important;
}

.hammer-btn .dropdown-toggle,
.show.hammer-btn > .dropdown-toggle {
  background-color: #fff;
  border: 1px solid #2d49fd;
  color: #2d49fd;
}

.hammer-btn .dropdown-toggle:hover:not(:disabled):not(.disabled),
.show.hammer-btn > .dropdown-toggle:hover:not(:disabled):not(.disabled) {
  color: #2d49fd;
}

.diff-actions {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 100;
  display: flex;
  gap: 8px;
}

.diff-actions button {
  padding: 6px 12px;
  font-size: 14px;
  border-radius: 4px;
}

@keyframes loading {
  0% { background-position: 200% 0; }
  100% { background-position: 0 0; }
}
