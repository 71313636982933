.DWH-form {
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  top: 135px;
  grid-gap: 47px;
  gap: 47px;
  background: linear-gradient(to left, #f7f8fa, rgba(255, 255, 255, 0.7));
  z-index: 120;
  overflow: hidden;
  width: calc(100% - 60px);
  right: 0;
  height: calc(100vh - 135px);
}

.DWH-form button.close-dwh {
  color: white;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  background: #abaaaa;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 15px;
  margin-right: 20px;
  margin-left: auto;
}

.DWH-form .close-dwh-icon {
  margin-right: 5px;
  margin-bottom: 3px;
}

.DWH-form button.btn-primary {
  background-color: #2d49fd;
}

.DWH-form .dwh-form-slide-enter {
  opacity: 0;
  transform: translateY(100%);
}

.DWH-form .dwh-form-slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.DWH-form .dwh-form-slide-exit {
  opacity: 1;
  transform: translateY(0);
}

.DWH-form .dwh-form-slide-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}
