.sidebar-modal-wrapper {
  position: fixed !important;
  top: 50px;
  right: 0;
  width: 600px;
  background-color: white;
  z-index: 2001;
  border: 2px solid #ebebeb;
  border-radius: 10px 0px 0px 10px;
  background: white;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.sidebar-modal-wrapper.fullscreen {
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.test-button {
  height: 34px; /* Adjust the height as needed */
  padding: 0 12px; /* Adjust the padding for proper spacing */
  display: flex;
  align-items: center;
}

.sidebar-modal-wrapper .schedule-sidebar-content {
  overflow: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.heading-schedule-model {
  color: black;
  font-size: 18px;
  margin-bottom: 28px;
}

.collapse-schedule {
  width: 100%;
  background-color: white;
  color: grey !important;
  border: 1px solid #ebebeb;
  color: grey;
  text-align: left;
  margin-bottom: 15px;
  height: 36px;
}

.collapse-schedule-chosen {
  color: #495057 !important;
}

.collapse-schedule:hover {
  background-color: #ebebeb;
  color: black !important;
}

.sidebar-modal-wrapper label {
  color: #4e4f61;
  margin-bottom: 4px;
}

.schedule-menu {
  width: 100%;
  padding: 5px 15px;
  border: 1px solid #ebebeb;
  list-style: none;
  border-radius: 5px;
  position: absolute; /* Ensure absolute positioning */
  background: white;
  z-index: 99999;
  max-height: 200px;
  overflow: auto;
}

ul hr.menu-divider {
  background: grey;
  margin: 0;
}

.link-lineage {
  color: #2d49fd;
}

.schedule-menu-item {
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
}

.schedule-menu-item:hover {
  background-color: #ebebeb;
  font-weight: 500;
}

.sidebar-modal-wrapper #workflowManager.Lineage-Vis {
  margin: 0 !important;
  margin-bottom: 20px !important;
}

.schedule-model-btns-wrapper .sc-btn:hover {
  background-color: #54cb75;
}

.schedule-model-btns-wrapper button:hover {
  opacity: 0.8;
}

.schedule-model-btns-wrapper button:disabled {
  background-color: gray;
}

.schedule-model-btns-wrapper .sc-btn:disabled {
  background-color: gray !important;
}

.test-btn,
.create-button {
  background-color: #2d49fd;
  border: none;
  padding: 12px 16px;
}

.create-button {
  padding: 6px 10px;
}

.sc-btn {
  background-color: #54cb75;
  border: none;
  padding: 12px 16px;
}

.sidebar-modal-wrapper button .button-icon {
  margin-right: 5px;
}

.sidebar-modal-wrapper input {
  border: 1px solid #ebebeb;
}

.sidebar-modal-wrapper .react-resizable {
  position: relative;
}
.sidebar-modal-wrapper .react-resizable-handle {
  position: absolute;
  width: 5px;
  height: 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.sidebar-modal-wrapper .react-resizable-handle-w,
.sidebar-modal-wrapper .react-resizable-handle-e {
  cursor: ew-resize;
}
.sidebar-modal-wrapper .react-resizable-handle-w {
  left: 0;
  top: 0;
}

.sidebar-modal-wrapper .schedule-sidebar-content .lineage-vis-height {
  height: calc(100vh - 550px);
}

.sidebar-modal-wrapper table.table-daged thead th {
  background-color: white !important;
  position: unset;
}

.sidebar-modal-wrapper table.table-daged {
  margin: 0 !important;
  width: 100% !important;
}

.sidebar-modal-wrapper table + div {
  margin: 20px 0 !important;
}
