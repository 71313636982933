.history-table-content-container {
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  top: 135px;
  grid-gap: 47px;
  gap: 47px;
  /* background: linear-gradient(to bottom, #f7f8fa, rgba(255, 255, 255, 0.7)); */
  background: linear-gradient(to left, #f7f8fa, rgba(255, 255, 255, 0.7));
  z-index: 120;
  overflow: hidden;
  width: calc(100% - 60px);
  right: 0;
  height: calc(100vh - 135px);
  overflow-y: auto;
}

.right-slide-enter {
  opacity: 0;
  transform: translateX(100%);
}

.right-slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.right-slide-exit {
  opacity: 1;
  transform: translateX(0);
}

.right-slide-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}

button.logs-trigger {
  background-color: #2d49fd;
  padding: 5px;
}

.first-table-elm {
  padding-left: 30px !important;
}

#history-table-container .accordion-button {
  background: white !important;
  color: #6c757d !important;
  gap: 10px;
  font-weight: normal !important;
}

/* Define the animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the SVG element */
#history-table-container .rotate-icon svg {
  animation: rotate 2s linear infinite; /* Adjust the duration as needed */
}

#history-table-container .accordion-button p {
  margin: 0;
}

#history-table-container .nav-tabs {
  border: none;
}

#history-table-container tr header {
  display: flex;
  gap: 10px;
  margin: 10px 0 15px 0;
  font-size: 12px;
}

#history-table-container .table-daged.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: transparent !important;
}

#history-table-container .log-accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#history-table-container .accordion-button {
  border: 1.5px solid #ebebeb;
  border-radius: 5px;
}

#history-table-container .accordion-button p {
  font-size: 11px;
}

#history-table-container .accordion-item {
  border: none;
}

#history-table-container .accordion-body {
  margin-top: 10px;
  border: 1.5px solid #ebebeb;
  border-radius: 5px;
}

#history-table-container .accordion-button:not(.collapsed) {
  box-shadow: none;
}

#history-table-container .nav-tabs .nav-link {
  font-size: 11px;
  padding: 10px 20px !important;
  color: #448593 !important;
}

#history-table-container a.nav-link.active {
  background-color: white !important;
  color: black !important;
}

.no-history-text {
  cursor: pointer;
}

.no-history-text:hover {
  text-decoration: underline;
}

.logs-container {
  background-color: black;
  color: white;
  padding: 10px 20px;
  overflow: auto;
  margin-bottom: 0;
}

.close-history-sidebar-button {
  color: white;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  background: #abaaaa;
  padding: 8.5px 15px;
  font-weight: 500;
  margin-left: auto;
  font-size: 15px;
  display: block;
}

.close-history-sidebar-icon {
  margin-right: 5px;
  margin-bottom: 3px;
}

.history-sidebar {
  width: 65%;
  margin-top: 10px;
  margin-right: 22px;
  height: calc(100vh - 165px);
  overflow: auto;
  background: white;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 5px;
  padding: 20px;
}

.history-heading-name {
  color: rgb(9, 14, 71);
  font-size: 14px;
  position: relative;
  top: 2px;
  word-break: break-all;
  max-width: calc(100% - 300px);
}

.history-heading {
  margin: 0px 8px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

#overview-create-workflow .history-table-content-container {
  top: 123px;
  height: calc(100vh - 120px);
}

#overview-edit-workflow .history-table-content-container {
  top: 123px;
  height: calc(100vh - 120px);
}

#overview-visualize-workflow .history-table-content-container {
  top: 128px;
  height: calc(100vh - 120px);
}
