.step-container {
  z-index: 888;
  position: relative;
  left: -10px;
  display: flex;
}

.step-outer-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-inner-circle {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
}

.step-name {
  margin: 0 5px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
}

.push-tap-stepper {
  width: 120px;
  position: sticky;
}

.step-indicator-container {
  height: calc(100% - 20px);
  width: 20px;
  background: #eaeaea;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
  flex-wrap: wrap;
}

.step-indicator-container .done-indicator {
  position: absolute;
  background: #b0eaaa;
  top: 0;
  left: 0;
  width: 20px;
  border-radius: 20px;
}

.push-tap-collector-form {
  display: flex;
  flex-direction: row-reverse;
  position: fixed;
  top: 135px;
  grid-gap: 47px;
  gap: 47px;
  background: linear-gradient(to left, #f7f8fa, rgba(255, 255, 255, 0.7));
  z-index: 120;
  overflow: hidden;
  width: calc(100% - 60px);
  right: 0;
  height: calc(100vh - 135px);
}

.connectors-container {
  margin: 0px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 140px);
  justify-content: space-between;
  width: calc(100% - 40px);
  margin-bottom: 20px;
}

#push-tap-creator-form .table-daged {
  width: calc(100% - 40px) !important;
}

#push-tap-creator-form
  .push-tap-form
  .react-select-notrain
  .react-select__control,
#push-tap-creator-form
  .push-tap-form
  .react-select-notrain
  .react-select__menu,
#push-tap-creator-form .push-tap-form input:not(.react-select__input) {
  width: calc(100% - 40px) !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

#push-tap-creator-form
  .push-tap-form
  .react-select-notrain
  .react-select__menu {
  margin-top: 4px;
}

#push-tap-creator-form .push-tap-form.push-form input {
  width: 100% !important;
}

#push-tap-creator-form .push-tap-form.push-form input:not(.push-alias-input) {
  margin: inherit !important;
}

#push-tap-creator-form b + i {
  color: #448593;
}

#push-tap-creator-form .push-tap-form input::placeholder {
  color: #448593;
  font-size: 10px;
}

#push-tap-creator-form .push-tap-form input + div,
#push-tap-creator-form .push-tap-form .react-select-container + div,
#push-tap-creator-form .push-tap-form .react-select-container + div b,
#push-tap-creator-form .push-tap-form .form-switch + div {
  color: #448593;
  width: 90%;
}

#push-tap-creator-form
  .push-tap-form.dataset-step
  .react-select-container
  + div {
  width: unset;
}

#push-tap-creator-form .switch-checker {
  /* display: none; */
  width: 100px !important;
  margin: 0 !important;
  padding-left: 20px;
}

#push-tap-creator-form .switch-checker input {
  margin: 5px 0 !important;
}

#push-tap-creator-form .card {
  min-height: 500px;
}

#push-tap-creator-form button.btn.btn-primary[type="submit"] {
  background-color: #2d49fd !important;
}

#push-tap-creator-form .card-taps {
  box-shadow: unset;
  box-shadow: none;
  margin-bottom: -5px;
}

#push-tap-creator-form .card-taps-body:hover {
  box-shadow: 0px 0px 21px 1px rgba(94, 94, 94, 0.1);
}

#push-tap-creator-form .card-taps-body {
  padding: 10px;
  padding-bottom: 10px;
}

#push-tap-creator-form .card-taps-body.connector-card-taps-body {
  border-radius: 8px;
  text-align: center;
  border: 2px solid #ebebeb;
  height: auto;
}

#push-tap-creator-form .connector-image {
  margin-bottom: 5px;
  width: 110px;
  height: 60px;
}

#push-tap-creator-form .close-push-tap-form {
  color: white;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  background: #abaaaa;
  padding: 8.5px 15px;
  font-weight: 500;
  margin-left: 10px;
  font-size: 15px;
}

#push-tap-creator-form .close-push-tap-form-icon {
  margin-right: 5px;
  margin-bottom: 3px;
}

#push-tap-creator-form .card-body > div {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.push-tap-slide-enter {
  opacity: 0;
  transform: translateY(100%);
}

.push-tap-slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.push-tap-slide-exit {
  opacity: 1;
  transform: translateY(0);
}

.push-tap-slide-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}

.connector-spinner {
  margin-top: 20px;
  grid-column: 1/-1;
}

.horizontal-spacing {
  margin: 20px;
}

.dataset-mapper .react-select-container {
  margin-top: 10px;
}

.form-step-wrapper {
  margin-top: -6px;
  height: calc(100vh - 160px);
  overflow: hidden auto;
}
